import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';

const BluePaymentForm = props => {
  const { paymentType, initBlue, setInitBlue, bluepayCustomerPaymentMethodList, saleOrderConfirm, user, doConfirmSaleOrder, saleOrderRead, saleOrderCreate, token } = props;
  const [cardErrors, setCardErrors] = useState([]);
  const [cardInstance, setCardInstance] = useState(null);
  const [cardData, setCardData] = useState({});
  const [routingNumber, setRoutingNumber] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [achSelection, setAchSelection] = React.useState('new');
  const [cardSelection, setCardSelection] = React.useState('new');

  const [accountHolderName, setAccountHolderName] = useState(user?.company_name);

  const [cardRequest, setCardRequest] = React.useState(false);
  const [achRequest, setAchRequest] = React.useState(false);

  const _onEvent = event => {
    if (event.error != null) {
      setCardErrors([...cardErrors, event.error]);
      const errorMessageEl = document.querySelector('#error-message');
      errorMessageEl.innerText = event.error;
    } else {
      const errorMessageEl = document.querySelector('#error-message');
      errorMessageEl.innerText = '';
    }
  };

  useEffect(() => {
    if (cardRequest) {
      setCardRequest(false);
      if (!saleOrderCreate?.fetching && !saleOrderRead?.fetching) {
        let payload = {
          cardSelection: cardSelection,
          card_data: cardData,
          sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
          source: paymentType,
        };
        doConfirmSaleOrder({ token, payload });
      }
    }
  }, [cardData, cardRequest, saleOrderRead, saleOrderCreate]);

  useEffect(() => {
    if (achRequest) {
      setAchRequest(false);
      if (!saleOrderCreate?.fetching && !saleOrderRead?.fetching) {
        let payload = {
          blueach_name: accountHolderName,
          blueach_routing: routingNumber,
          blueach_account: accountNumber,
          blueach_selection: achSelection,
          sale_order_id: saleOrderRead?.data ? saleOrderRead?.data?.id : saleOrderCreate?.data?.id,
          source: paymentType,
        };
        doConfirmSaleOrder({ token, payload });
      }
    }
  }, [routingNumber, accountNumber, achRequest, saleOrderRead, saleOrderCreate]);

  useEffect(() => {
    if (paymentType === 'ach') {
      setInitBlue(false);
    }
    if (document.querySelector('#my-div') == null) {
      return;
    }
    if (initBlue) {
      return; // Early return if initBlue is already true
    }
    setInitBlue(true);

    const tokenizationSourceKey = window.BLUEPAY_PUBLIC;
    const hostedTokenization = new window.HostedTokenization(tokenizationSourceKey);
    if (!hostedTokenization) {
      return; // Early return if hostedTokenization is null
    }
    let cardForm = hostedTokenization.create('card-form');

    if (cardForm === undefined || cardForm === null) {
      return; // Early return if cardForm is undefined or null
    }
    setCardInstance(cardForm);
    cardForm
      .mount('#my-div')
      .on('input', _onEvent)
      .on('change', _onEvent);

    cardForm.setStyles({
      card: 'border: 1px solid black;padding: 16.5px 14px;width:90%;margin-bottom:10px;',
      expiryMonth: 'border: 1px solid black;padding: 16.5px 14px;width:20%;',
      expiryYear: 'border: 1px solid black;padding: 16.5px 14px;width:20%;',
      cvv2: 'border: 1px solid black;padding: 16.5px 14px;width:30%;',
    });
  }, [initBlue, setInitBlue, paymentType]);

  return (
    <Grid container>
      {
        {
          card: (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div id="error-message" style={{ color: `red`, fontWeight: `bold` }}></div>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel style={{ paddingBottom: 16 }}>Use Existing Card</FormLabel>
                  <RadioGroup
                    row
                    value={cardSelection}
                    onChange={event => {
                      setCardSelection(event.target.value);
                    }}
                  >
                    {bluepayCustomerPaymentMethodList?.data && bluepayCustomerPaymentMethodList?.data.length >= 1
                      ? bluepayCustomerPaymentMethodList?.data
                          ?.filter(item => item.payment_method_type === 'CARD')
                          .map(payment => (
                            <Card key={payment.id} style={{ marginRight: 16 }}>
                              <CardContent style={{ paddingBottom: 16 }}>
                                <FormControlLabel
                                  value={payment.id}
                                  control={<Radio />}
                                  label={
                                    <div>
                                      <CreditCardIcon fontSize={`large`} />
                                      <div style={{ textAlign: `center` }}>{payment?.last4}</div>
                                    </div>
                                  }
                                />
                              </CardContent>
                            </Card>
                          ))
                      : ''}
                    <Card key={'new'}>
                      <CardContent style={{ paddingBottom: 16 }}>
                        <FormControlLabel value={'new'} control={<Radio />} label={'New Card'} />
                      </CardContent>
                    </Card>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <form id={`my-div`} style={{ display: cardSelection !== 'new' ? 'none' : 'block', width: `100%` }}></form>
                <div style={{ position: `relative`, width: `100%` }}>
                  <div style={{ width: `100%` }}>
                    <LoadingButton
                      type={`submit`}
                      loading={saleOrderConfirm?.fetching}
                      disabled={saleOrderConfirm?.fetching}
                      loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={64} />}
                      size={`large`}
                      variant="contained"
                      color="primary"
                      style={{ width: `100%` }}
                      fullWidth={true}
                      onClick={async event => {
                        try {
                          const result = await cardInstance.getNonceToken();
                          setCardData(result);
                          setCardRequest(true);
                        } catch (e) {
                          if (cardSelection !== 'new') {
                            setCardRequest(true);
                          }
                        }
                      }}
                    >
                      {saleOrderConfirm?.fetching ? <CreditCardIcon /> : 'Pay'}
                    </LoadingButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          ),
          ach: (
            <Grid container>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel style={{ paddingBottom: 16 }}>Use Existing Ach</FormLabel>
                  <RadioGroup
                    row
                    value={achSelection}
                    onChange={event => {
                      setAchSelection(event.target.value);
                    }}
                  >
                    {bluepayCustomerPaymentMethodList?.data && bluepayCustomerPaymentMethodList?.data.length >= 1
                      ? bluepayCustomerPaymentMethodList?.data
                          ?.filter(item => item.payment_method_type === 'ACH')
                          .map(payment => (
                            <Card key={payment.id} style={{ marginRight: 16 }}>
                              <CardContent style={{ paddingBottom: 16 }}>
                                <FormControlLabel
                                  value={payment.id}
                                  control={<Radio />}
                                  label={
                                    <div>
                                      <AccountBalanceIcon fontSize={`large`} />
                                      <div style={{ textAlign: `center` }}>{payment?.last4}</div>
                                    </div>
                                  }
                                />
                              </CardContent>
                            </Card>
                          ))
                      : ''}
                    <Card key={'new'}>
                      <CardContent style={{ paddingBottom: 16 }}>
                        <FormControlLabel value={'new'} control={<Radio />} label={'New Ach'} />
                      </CardContent>
                    </Card>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid container>
                {achSelection === 'new' ? (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: 10, marginTop: 20 }}>
                        <TextField label="Account Holder Name" value={accountHolderName} fullWidth variant="outlined" onChange={event => setAccountHolderName(event.target.value)} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <hr style={{ marginBottom: 15 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: 10 }}>
                        <TextField label="Routing #" value={routingNumber} fullWidth variant="outlined" onChange={event => setRoutingNumber(event.target.value)} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: 10 }}>
                        <TextField label="Account #" value={accountNumber} fullWidth variant="outlined" onChange={event => setAccountNumber(event.target.value)} />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <LoadingButton
                        loading={saleOrderConfirm?.fetching}
                        disabled={saleOrderConfirm?.fetching}
                        loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={64} />}
                        size={`large`}
                        variant="contained"
                        color="primary"
                        style={{ width: `100%` }}
                        fullWidth={true}
                        onClick={event => {
                          setAchRequest(true);
                        }}
                      >
                        PAY
                      </LoadingButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <LoadingButton
                          loading={saleOrderConfirm?.fetching}
                          disabled={saleOrderConfirm?.fetching}
                          loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={64} />}
                          size={`large`}
                          variant="contained"
                          color="primary"
                          style={{ width: `100%` }}
                          fullWidth={true}
                          onClick={event => {
                            setAchRequest(true);
                          }}
                        >
                          PAY
                        </LoadingButton>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ),
        }[paymentType]
      }
    </Grid>
  );
};

// PROPS TYPES
// --------------------------------------------------------
BluePaymentForm.propTypes = {
  history: PropTypes.object,
  token: PropTypes.string,
  match: PropTypes.object,
  initBlue: PropTypes.bool,
  paymentType: PropTypes.string,

  doConfirmSaleOrder: PropTypes.func,
  setInitBlue: PropTypes.func,
  user: PropTypes.object,
  saleOrderRead: PropTypes.object,
  saleOrderCreate: PropTypes.object,
  bluepayCustomerPaymentMethodList: PropTypes.object,
  saleOrderConfirm: PropTypes.object,
};
export default BluePaymentForm;
